const mapEnum = {
  // 是否
  hasContractOptions: [
    { label: '是', value: 1 },
    { label: '否', value: 0 },
  ],
  platformType: [
    { label: 'YT', value: 'YT' },
    { label: 'FB', value: 'FB' },
  ],
  // 到账状态
  AccountStatus: [
    { label: '已到账', value: 1 },
    { label: '应到未到', value: 2 },
    { label: '未到账', value: 3 },
  ],
  // cms搜索
  cmsSearch: [
    { label: 'XW', value: 'XW' },
    { label: 'AC', value: 'AC' },
  ],
  // cms
  cmsOptions: [
    { label: '小五', value: 'XW' },
    { label: '亚创', value: 'AC' },
    { label: 'Adsense-HK', value: 'Adsense-HK' },
    { label: 'Adsense-US', value: 'Adsense-US' },
  ],
  // 是否CID
  isCidOptions: [
    { label: 'self', value: 'self' },
    { label: 'thirdParty', value: 'thirdParty' },
  ],
  // 频道类型
  channelTypeOptions: [
    { label: '单开', value: 0 },
    { label: '子集', value: 1 },
    { label: '合集', value: 2 },
  ],
  // Content Type
  contentTypeOptions: [
    { label: 'UGC', value: 'UGC' },
    { label: 'Partner-provided', value: 'Partner-provided' },
  ],
  // 分成模式
  sharePatternOptions: [
    { label: '纯分成模式', value: '纯分成模式' },
    { label: '预支分成模式', value: '预支分成模式' },
    { label: '版权采买模式', value: '版权采买模式' },
    { label: '收益梯度模式', value: '收益梯度模式' },
    { label: '其他', value: '其他' },
  ],
  statusOptions: [
    { label: '未生成', value: 0, color: '#FFA80F' },
    { label: '已生成', value: 1, color: '#00B09B' },
    { label: '生成失败', value: 2, color: '#F24545' },
  ],
  // 生成类型
  generationType: [
    { label: '全部生成', value: 0 },
    { label: '勾选生成', value: 1 },
  ],
  // 结算单类型
  statementTypeOptions: [
    { label: '正常', value: 'normal' },
    { label: '信息变更', value: 'contractChange' },
    { label: '新增汇款', value: 'newPayment' },
    { label: '存疑结算', value: 'doubtfulPayment' },
  ],
  // 结算单生成状态
  generateStatusStatement: [
    { label: '全部', value: '' },
    { label: '未生成', value: 0, color: '#FFA80F' },
    { label: '已生成', value: 1, color: '#00B09B' },
    { label: '无需生成', value: 2, color: '#8d8d8d' },
  ],
  // 结算单审核状态
  checkStatusStatement: [
    { label: '待客服审核', value: 1 },
    { label: '待财务审核', value: 2 },
    { label: '待客服二审', value: 3 },
    { label: '已推送', value: 4 },
    { label: '暂不结算', value: 5 },
  ],
  // 发票状态
  invoiceStatusOptions: [
    { label: '待上传', value: 0, color: '#2CA0F8' },
    { label: '待审核', value: 1, color: '#2CA0F8' },
    { label: '审核通过', value: 2, color: '#00B09B' },
    { label: '已驳回', value: 3, color: '#F24545' },
    { label: '待发起', value: 4, color: '#2CA0F8' },
    { label: '待签字', value: 5, color: '#FFA80F' },
    { label: '已签字', value: 6, color: '#00B09B' },
    { label: '待生成', value: 7, color: '#2CA0F8' },
    { label: '已生成', value: 8, color: '#00B09B' },
    { label: '已确认', value: 9, color: '#00B09B' },
    { label: '发起失败', value: 10, color: '#F24545' },
  ],
  // 结算单汇款状态
  paymentStatusStatement: [
    { label: '待汇款', value: 0, color: '#2CA0F8' },
    { label: '已汇款', value: 1, color: '#00B09B' },
    { label: '其他', value: '-1', color: '#FFA80F' },
  ],
  // 发票汇款状态
  paymentStatusInvoice: [
    { label: '待汇款', value: 0, color: '#2CA0F8' },
    { label: '已汇款', value: 1, color: '#00B09B' },
    { label: '部分已汇款', value: 2, color: '#FFA80F' },
  ],
  // 发票类型
  invoiceTypeOptions: [
    { label: '全部', value: '' },
    { label: '电票', value: 1 },
    { label: '纸票', value: 2 },
  ],
  // 发票回执状态
  sendSmsStatusOptions: [
    { label: '未发送', value: 0, color: '#2CA0F8' },
    { label: '已发送待回执', value: 1, color: '#FFA80F' },
    { label: '已发送', value: 2, color: '#00B09B' },
    { label: '发送失败', value: 3, color: '#F24545' },
  ],
  // 发票审核状态
  checkStatusInvoice: [
    { label: '待审核', value: 0, color: '#2CA0F8' },
    { label: '审核通过', value: 1, color: '#00B09B' },
    { label: '已驳回', value: -1, color: '#F24545' },
    { label: '待上传', value: 3, color: '#2CA0F8' },
  ],
  // 发票类型
  ticketTypeOptions: [
    { label: '数电专票', value: 0 },
    { label: '数电纸专', value: 1 },
    { label: '电专', value: 2 },
    { label: '纸专', value: 3 },
    { label: '数电普票', value: 4 },
    { label: '数电纸普', value: 5 },
    { label: '电普', value: 6 },
    { label: '纸普', value: 7 },
  ],
  // 电子发票签字状态
  signStatusOptions: [
    { label: '待发起', value: 0, color: '#2CA0F8' },
    { label: '待签字', value: 1, color: '#FFA80F' },
    { label: '已签字', value: 2, color: '#00B09B' },
    { label: '待生成', value: 7, color: '#2CA0F8' },
    { label: '发起失败', value: 5, color: '#F24545' },
  ],
  // 发票状态
  statusInvoice: [
    { label: '待生成', value: 0, color: '#2CA0F8' },
    { label: '已生成', value: 1, color: '#FFA80F' },
    { label: '已确认', value: 2, color: '#00B09B' },
    { label: '作废', value: 3, color: '#F24545' },
  ],
  // 报表类型
  report: {
    YT: [
      { label: 'YT暂估表(老)', value: 'YT-estimate' },
      { label: 'YT暂估表(新)', value: 'YT-estimate-new' },
      { label: 'YT冲销表(老)', value: 'YT-reversal' },
      { label: 'YT冲销表(新)', value: 'YT-reversal-new' },
      { label: 'YT月初视频级', value: 'YT-monthApi' },
      { label: 'YT财务报告频道级', value: 'YT-monthReport' },
      { label: 'YT月初API频道级', value: 'YT-monthOriginalStart' },
      { label: 'YT月末API频道级', value: 'YT-monthOriginalEnd' },
    ],
    FB: [
      { label: 'FB暂估表', value: 'FB-estimate' },
      { label: 'FB INVOICE表', value: 'FB-invoice' },
      { label: 'FB REMITTANCE表', value: 'FB-remittance' },
      { label: 'FB到账校验表', value: 'FB-verification' },
    ],
  },
  isZeroOptions: [
    { label: '0', value: 0 },
    { label: '非0', value: 1 },
  ],
  exportType: {
    1: [
      { label: '待汇款结算单', value: 0, subLabel: '发票审核通过，但还没有汇款的结算单' },
      { label: '待审核通过结算单', value: 1, subLabel: '未推送、以及推送后发票尚未审核通过的所有结算单' },
      { label: '全部结算单', value: 2 },
    ], // 境内企业
    2: [
      { label: '待汇款结算单', value: 0, subLabel: 'CP已签字，但还没有汇款的结算单' },
      { label: '待CP确认结算单', value: 1, subLabel: '未推送、以及推送后CP尚未签字的所有结算单' },
      { label: '全部结算单', value: 2 },
    ], // 境内个人
    3: [
      { label: '待汇款结算单', value: 0, subLabel: '发票审核通过，但还没有汇款的结算单' },
      { label: '待审核通过结算单', value: 1, subLabel: '未推送、以及推送后发票尚未审核通过的所有结算单' },
      { label: '全部结算单', value: 2 },
    ], // 境外企业
    4: [
      { label: '待汇款结算单', value: 0, subLabel: '已确认，但还没有汇款的结算单' },
      { label: '待确认结算单', value: 1, subLabel: '未推送、以及推送后尚未代确认的所有结算单' },
      { label: '全部结算单', value: 2 },
    ], // 境外个人
  },
  cpDataStatisticsMap: [
    { label: '境内个人', value: 2, pendingConfirmKey: 'pendingConfirmRmb', pendingPaymentKey: 'pendingPaymentRmb', actualIncomeKey: 'actualIncomeRmb', unit: '￥' },
    { label: '境内企业', value: 1, pendingConfirmKey: 'pendingConfirmRmb', pendingPaymentKey: 'pendingPaymentRmb', actualIncomeKey: 'actualIncomeRmb', unit: '￥' },
    { label: '境外个人', value: 4, pendingConfirmKey: 'pendingConfirmDollar', pendingPaymentKey: 'pendingPaymentDollar', actualIncomeKey: 'actualIncomeDollar', unit: '$' },
    { label: '境外企业', value: 3, pendingConfirmKey: 'pendingConfirmDollar', pendingPaymentKey: 'pendingPaymentDollar', actualIncomeKey: 'actualIncomeDollar', unit: '$' },
  ],
  // fb部门
  operationOptionsFB: [
    { label: '无运营团队', value: 'none' },
    { label: '杭州FB运营组', value: 'hz_fb' },
    { label: '晋城FB运营1组', value: 'jc_fb_1' },
    { label: '晋城FB运营2组', value: 'jc_fb_2' },
    { label: '晋城FB运营3组', value: 'jc_fb_3' },
    { label: '杭州影视', value: 'hz_slice' },
    { label: '晋城影视', value: 'jc_slice' },
    { label: '杭州FB孵化组', value: 'hz_incubate' },
    { label: '晋城FB孵化组', value: 'jc_incubate' },
  ],
  // 主页类型
  operationType: [
    { label: '影视', value: 'slice' },
    { label: '原创', value: 'original' },
    { label: '代运营', value: 'acting' },
    { label: '影视代运营', value: 'film_acting' },
    { label: '原创代运营', value: 'original_acting' },
    { label: '原创自制', value: 'original_own' },
    { label: '自运营', value: 'self' },
    { label: '自运营-符合变现地区所在国家', value: 'self_acc_country' },
    { label: '自运营-不符合变现地区所在国家', value: 'self_not_acc_country' },
  ],
  // 全部
  operationAddOptions: [
    { label: 'FB事业部', value: 'hz_fb' },
    { label: '晋城FB运营中心', value: 'jc_fb' },
    { label: '晋城FB运营1组', value: 'jc_fb_1' },
    { label: '晋城FB运营2组', value: 'jc_fb_2' },
    { label: '晋城FB运营3组', value: 'jc_fb_3' },
    { label: '杭州影视', value: 'hz_slice' },
    { label: '晋城影视', value: 'jc_slice' },
    { label: '杭州代运营', value: 'hz_mcn' },
    { label: 'YT事业部', value: 'jc_mcn' },
    { label: '国际事业部', value: 'hz_original' },
    { label: '晋城原创', value: 'jc_original' },
    { label: '上交公司', value: 'hand_over_company' },
    { label: '郑州FB孵化组', value: 'zz_incubate' },
    { label: '杭州FB孵化组', value: 'hz_incubate' },
    { label: '濮阳FB孵化组', value: 'py_incubate' },
    { label: '濮阳FB运营组', value: 'py_fb' },
    { label: '晋城FB孵化组', value: 'jc_incubate' },
    { label: '上交公司', value: 'hand_over_company' },
    { label: 'TK事业部', value: 'hz_tiktok' },
    { label: '无运营团队', value: 'none' },
  ],
  // 运营团队
  operationOptions: [
    { label: 'YT事业部', value: 'jc_mcn' },
    { label: 'FB事业部', value: 'hz_fb' },
    { label: '国际事业部', value: 'hz_original' },
    { label: '杭州影视', value: 'hz_slice' },
    { label: '晋城原创', value: 'jc_original' },
    { label: '晋城影视', value: 'jc_slice' },
    { label: '上交公司', value: 'hand_over_company' },
    { label: 'TK事业部', value: 'hz_tiktok' },
  ],
  // 运营团队
  operationTextOptions: [
    { label: '影视事业部', value: '影视事业部' },
    { label: 'YT事业部', value: 'YT事业部' },
    { label: '原创事业部', value: '原创事业部' },
  ],
}

export default mapEnum

export function filterMap(key, val) {
  const arr = mapEnum[key] || []
  const data = arr.find(item => item.value === val)

  return (data ? data.label : val) || '-'
}

export function filterMapColor(arrKey, val, key = 'value') {
  const arr = mapEnum[arrKey] || []
  const data = arr.find(item => item[key] === val)

  return (data && data.color) || ''
}

export function findItem(arrKey, val, key = 'value') {
  const arr = mapEnum[arrKey] || []
  const data = arr.find(item => item[key] === val)

  return data || {}
}
