<script>
import { computed, getCurrentInstance, inject, ref, onBeforeUnmount, watch } from '@vue/composition-api'
import { isNull } from '@core/utils/is'
import { isEmpty } from '@core/utils'

export default {
  name: 'DateTimeRange',
  components: {},
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: {
      type: [String, Array],
      default: () => null,
    },
    rangeSeparator: {
      type: String,
      default: '-',
    },
    startPlaceholder: {
      type: String,
      default: '开始日期',
    },
    endPlaceholder: {
      type: String,
      default: '结束日期',
    },
    errorMessage: {
      type: String,
      default: '这是必填项',
    },
    required: Boolean,
  },
  setup(props, { emit }) {
    const vm = getCurrentInstance().proxy
    const form = inject('form', {})
    const visibleError = ref(false)
    const visibleMessage = ref(props.errorMessage)
    const dateValue = computed({
      get() {
        return props.value
      },
      set(val) {
        if (isNull(val)) return emit('input', [])

        return emit('input', val)
      },
    })

    if (form.register) {
      form.register(vm)
    }

    onBeforeUnmount(() => {
      if (form.unregister) form.unregister(vm)
    })

    function validate() {
      if (isEmpty(props.value) && props.required) {
        visibleError.value = true

        return false
      }

      visibleError.value = false

      return true
    }

    function reset() {
      visibleError.value = false
      emit('input', [])
    }

    function resetValidation() {
      visibleError.value = false
    }

    watch(() => props.value, () => {
      if (!props.value) emit('input', [])
      validate()
    })

    return { dateValue, validate, reset, resetValidation, visibleMessage, visibleError }
  },
}
</script>
<template>
  <div>
    <el-date-picker
      v-model="dateValue"
      style="width: 100%;"
      type="datetimerange"
      :range-separator="rangeSeparator"
      :start-placeholder="startPlaceholder"
      :end-placeholder="endPlaceholder"
      value-format="yyyy-MM-dd HH:mm:ss"
      popper-class="datePickerClass"
      :class="[visibleError? 'error-picker' : '']"
      v-bind="$attrs"
    >
    </el-date-picker>
    <div v-show="visibleError" class="error-picker-message">
      {{ visibleMessage }}
    </div>
  </div>
</template>
<style scoped lang='scss'>
::v-deep .el-time-panel{
  width: 150px !important;
}

.error-picker {
  border: 2px solid var(--v-error-base) !important;

  &-message {
    height: max-content;
    color: var(--v-error-base) !important;
    caret-color: var(--v-error-base) !important;
    transform: translateY(5px);
    flex: 1 1 auto;
    font-size: 12px;
    min-height: 14px;
    min-width: 1px;
    position: relative;
    line-height: 12px;
    word-break: break-word;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
    font-weight: 400;
  }
}
</style>
