import store from '@/store'
import useJwt from './jwt/useJwt'

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  return localStorage.getItem('userData') && localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
}

export const getUserData = () => store.state.user

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === '超级管理员') return '/'
  if (userRole === 'client') return { name: 'access-control' }

  return { name: 'auth-login' }
}

/**
 * 或者该路由页面下dataAuth权限数据
 * @param {页面路由} router
 * @returns
 */
export const convertRouterToList = router => {
  const { dataAuth } = store.getters

  return [{ text: '', value: 'checkbox', view: true }, ...dataAuth[router], { text: '操作', value: 'actions', view: true }]
}

/**
 * 获取权限的表头表格
 * @param {本地表格} oldArr
 * @param {页面路由} router
 * @param {权限类型} type
 * @returns
 */
export const filterTableColumns = (oldArr, router, type = 'view') => {
  const newArr = convertRouterToList(router)

  if (!newArr.length) return oldArr
  // 筛选权限类型
  const filterNewArr = newArr.filter(item => item[type])
  // 取两个数组的value交集

  return [...oldArr.filter(item1 => filterNewArr.some(item2 => (item1.apiValue || item1.value) === item2.value))]
}

/**
 * 获取导出权限字段
 * @param {表头} header
 * @param {页面路由} router
 * @returns
 */
export const getExportTableColumns = (header, router) => filterTableColumns(header, router, 'export').filter(column => !['checkbox', 'actions'].includes(column.value)).map(item => item.apiText || item.text.replace(/\s+/g, '')).join(',')

/**
 * 获取字段权限
 * @param {页面路由} router
 * @param {需要获取权限的字段} filed
 * @param {权限类型} type
 * @returns
 */
export const getFiledAuth = (router, filed, type = 'view') => {
  const newFiled = convertRouterToList(router).find(item => item.value === filed)
  if (!newFiled) return false

  return newFiled[type]
}

export function permissionFiltering(value) {
  const { buttonAuth } = store.getters

  return new Promise((resolve, reject) => {
    if (value === undefined || (Array.isArray(value) && value.length === 0)) return resolve(true)
    if (Array.isArray(value) && value.length) {
      const hasPermission = value.some(item => buttonAuth.includes(item))

      return hasPermission ? resolve(true) : reject(new Error('没有为您分配此操作权限'))
    }

    return buttonAuth.includes(value) ? resolve(true) : reject(new Error('没有为您分配此操作权限'))
  })
}
