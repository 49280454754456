<script>
import { debounce } from '@/@core/utils/form'
import moment from 'moment'
import XwTotalAmount from '@/components/XwTotalAmount.vue'
import { ref, reactive, onMounted, computed, nextTick } from '@vue/composition-api'
import TabsPro from '@/components/tabs/TabsPro.vue'
import TabPanePro from '@/components/tabs/TabPanePro.vue'
import { addSpace, processNumbers } from '@/@core/utils'
import SearchForm from '@/components/SearchForm/index.vue'
import { useMessage } from '@/hooks/useMessage'
import AppView from '@/components/AppView.vue'
import Pagination from '@/components/Pagination.vue'
import SuperTable from '@/components/SuperTable.vue'
import mapEnum, { findItem } from '@/enum/mapEnum'
import exportDialog from './components/exportDialog.vue'
import { getPage, pageStatistics, updateInit } from './service'

export default {
  name: 'DataStatistics',
  components: { TabsPro, TabPanePro, SearchForm, AppView, Pagination, SuperTable, XwTotalAmount, exportDialog },
  props: {},
  setup() {
    const activeName = ref('境内个人')
    const activeValue = ref(2)
    const searchForm = reactive({
      recentPayoutPeriod: undefined,
      cpName: undefined,
      pendingPaymentStatus: undefined,
    })

    const tabList = computed(() => [
      { label: '境内个人', value: 2 },
      { label: '境内企业', value: 1 },
      { label: '境外个人', value: 4 },
      { label: '境外企业', value: 3 },
    ])

    const tableRef = ref()
    const formRef = ref()
    const selected = ref([])
    const visibleExport = ref(false)
    const { message, confirm, loading: load } = useMessage()
    const loading = ref(false)
    const page = reactive({
      page: 1,
      pageSize: 20,
      total: 0,
    })
    const newSearchForm = computed(() => ({
      ...searchForm,
      cpType: activeValue.value,
    }))

    const findItemData = computed(() => findItem('cpDataStatisticsMap', activeValue.value))

    const searchConfig = computed(() => [
      {
        el: 'VTextField', props: 'cpName', label: 'CP名称',
      },
      {
        el: 'VSelect', props: 'pendingPaymentStatus', label: '待汇款金额', items: mapEnum.isZeroOptions, itemText: 'label', itemValue: 'value',
      },
      {
        el: 'DatePickers', props: 'recentPayoutPeriod', label: '最后结算Period', clearable: true, type: 'month', showCurrent: moment().subtract(1, 'month').format('yyyy-MM'),
      },
    ])

    const tableColumns = computed(() => [
      { text: '', value: 'checkbox', width: 50, type: 'checkbox' },
      { text: 'CP名称', value: 'cpName', minWidth: 180, showOverflow: false },
      { text: '结算单数量', value: 'settlementCount', minWidth: 180, align: 'right' },
      { text: `结算总金额 (${findItemData.value.unit})`, value: 'actualIncomeKey', minWidth: 180, align: 'right' },
      { text: `待CP确认金额 (${findItemData.value.unit})`, value: 'pendingConfirmKey', minWidth: 180, align: 'right' },
      { text: `待汇款金额 (${findItemData.value.unit})`, value: 'pendingPaymentKey', minWidth: 180, align: 'right' },
      { text: '最后结算Period', value: 'recentPayoutPeriod', minWidth: 180 },
      { text: '最近更新时间', value: 'updatedAt', minWidth: 180 },
    ])

    const newSearchConfig = computed(() => searchConfig.value.filter(item => !item.auth))

    const statisticsForm = ref({})
    const tableData = ref([])
    const init = () => {
      loading.value = true
      getPage({ ...page, total: undefined, ...newSearchForm.value, cpType: activeValue.value }).then(({ data }) => {
        loading.value = false
        tableData.value = data.data
        page.total = data.total
        selected.value = []
        tableRef.value.clear()
      }).catch(error => {
        loading.value = false
        tableData.value = []
        page.total = 0
        message.error(error.response.data.message)
      })
    }

    const getStatisticsForm = () => {
      pageStatistics({ ...newSearchForm.value }).then(({ data }) => {
        statisticsForm.value = (data.data || {})
      }).catch(error => message.error(error.response.data.message))
    }

    const created = () => {
      init()
      getStatisticsForm()
    }

    // 导出
    const exportExcel = () => {
      visibleExport.value = true
    }

    // 更新
    const update = () => {
      confirm(selected.value.length ? `已勾选 ${selected.value.length} 条，将重新生成所选数据，是否确认更新？` : '当前未勾选任何数据，将重新生成全部数据，是否确认更新？', '提示', { type: 'warning', confirmButtonText: addSpace('确认'), cancelButtonText: addSpace('取消') }).then(() => {
        const memberIdList = selected.value.map(item => item.memberId)
        const loadRef = load()
        updateInit({ memberIdList, cpType: activeValue.value }).then(() => {
          message.success('操作成功')
          created()
        }).catch(error => message.error(error.response.data.message))
          .finally(() => loadRef.close())
      }).catch(() => {})
    }

    const updateDebounceFn = debounce(update, 800)
    const selectionChange = data => {
      selected.value = data
    }

    function handleCurrentChange(val) {
      page.page = val
      init()
    }

    function handleSizeChange(val) {
      page.page = 1
      page.pageSize = val
      init()
    }

    const reset = async () => {
      await formRef.value.resetForm()
      nextTick(() => {
        created()
      })
    }

    const search = () => {
      page.page = 1
      created()
    }

    const tabClick = val => {
      activeName.value = val.name
      activeValue.value = tabList.value.find(item => item.label === val.name).value
      search()
    }

    const totalList = computed(() => ([
      { label: '结算总金额', amount: statisticsForm.value[findItemData.value.actualIncomeKey], unit: findItemData.value.unit },
      { label: '待确认金额', amount: statisticsForm.value[findItemData.value.pendingConfirmKey], unit: findItemData.value.unit },
      { label: '待汇款金额', amount: statisticsForm.value[findItemData.value.pendingPaymentKey], unit: findItemData.value.unit },
    ]))

    onMounted(() => {
      init()
      getStatisticsForm()
    })

    return {
      activeName,
      tabClick,
      tabList,
      searchForm,
      exportExcel,
      newSearchConfig,
      page,
      selectionChange,
      handleCurrentChange,
      handleSizeChange,
      reset,
      search,
      addSpace,
      tableData,
      loading,
      tableColumns,
      totalList,
      updateDebounceFn,
      tableRef,
      visibleExport,
      activeValue,
      findItem,
      processNumbers,
      findItemData,
      formRef,
      newSearchForm,
    }
  },
}
</script>
<template>
  <v-card class="app-list">
    <tabs-pro v-model="activeName" style="height: 100%" @tabClick="tabClick">
      <tab-pane-pro v-for="item in tabList" :key="item.value" :label="`${item.label}`" :name="item.label"></tab-pane-pro>
    </tabs-pro>
    <div class="app-body">
      <AppView>
        <template #header>
          <div
            class="flex-vertical"
            style="width: 100%"
          >
            <SearchForm
              ref="formRef"
              :columns="newSearchConfig"
              :value="searchForm"
              :search="search"
              :reset="reset"
            />
          </div>
        </template>

        <template #main-header>
          <div class="d-flex justify-space-between align-end">
            <div class="d-flex align-center">
              <v-btn v-auth="['data-statistics-update']" class="app-link" color="primary" @click="updateDebounceFn">
                {{ addSpace('更新') }}
              </v-btn>
              <v-btn v-auth="['data-statistics-export']" class="app-link" outlined @click="exportExcel">
                {{ addSpace('导出') }}
              </v-btn>
            </div>

            <XwTotalAmount :list="totalList" />
          </div>
        </template>

        <SuperTable
          ref="tableRef"
          :columns="tableColumns"
          :data="tableData"
          :loading="loading"
          :checkbox-config="{reserve:true}"
          :row-config="{keyField:'id'}"
          @selection-change="selectionChange"
        >
          <template #actualIncomeKey="{row}">
            {{ processNumbers(row[findItemData.actualIncomeKey]) }}
          </template>
          <template #pendingConfirmKey="{row}">
            {{ processNumbers(row[findItemData.pendingConfirmKey]) }}
          </template>
          <template #pendingPaymentKey="{row}">
            {{ processNumbers(row[findItemData.pendingPaymentKey]) }}
          </template>
        </SuperTable>

        <template #footer>
          <Pagination
            :page-size="page.pageSize"
            :page-index="page.page"
            :total="page.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </template>
      </AppView>
    </div>

    <exportDialog v-if="visibleExport" title="导出" :cp-type="activeValue" :form-search="searchForm" @close="visibleExport = false" />
  </v-card>
</template>
<style scoped lang='scss'>
::v-deep .tab-Box{
  height: 50px !important;
}
.app-body{
  height: calc(100% - 50px);
}
</style>
