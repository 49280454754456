<script>
import { isNull } from '@core/utils/is'
import { isEmpty } from '@core/utils'

export default {
  name: 'DateMonthRange',
  components: {},
  inject: ['form'],
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: {
      type: [String, Array],
      default: () => null,
    },
    rangeSeparator: {
      type: String,
      default: '-',
    },
    startPlaceholder: {
      type: String,
      default: '开始月份',
    },
    endPlaceholder: {
      type: String,
      default: '结束月份',
    },
    spanDay: {
      type: Number,
      default: 180, // 天
    },
    errorMessage: {
      type: String,
      default: '这是必填项',
    },
    required: Boolean,
  },
  data() {
    return {
      defaultSpan: 1000 * 60 * 60 * 24,
      pickerMindate: null,
      pickerOptions: {
        onPick: ({ minDate }) => {
          if (minDate && this.pickerMindate) {
            this.pickerMindate = null
          } else if (minDate) {
            this.pickerMindate = minDate.getTime()
          }
        },
        disabledDate: time => {
          if (this.pickerMindate) {
            return time.getTime() > this.pickerMindate + (this.timeLimit) || time.getTime() < this.pickerMindate - (this.timeLimit)
          }

          return false
        },
      },

      visibleError: false,

    }
  },

  computed: {
    dateValue: {
      get() {
        return this.value
      },
      set(val) {
        if (isNull(val)) return this.$emit('input', [])

        return this.$emit('input', val)
      },
    },
    timeLimit: {
      get() {
        return this.defaultSpan * this.spanDay
      },
    },
    visibleMessage: {
      get() {
        if (this.required) {
          return this.errorMessage
        }

        return ''
      },
      set(val) {
        console.log(val)
      },
    },
  },

  watch: {

    value() {
      if (!this.value) this.$emit('input', [])
      this.validate()
    },
  },

  mounted() {
    if (this.form) {
      this.form.register(this)
    }
  },

  beforeDestroy() {
    if (this.form) {
      this.form.unregister(this)
    }
  },

  methods: {
    reset() {
      this.visibleError = false
      this.$emit('input', [])
    },

    resetValidation() {
      this.visibleError = false
    },
    validate() {
      if (isEmpty(this.value) && this.required) {
        this.visibleError = true

        return false
      }

      this.visibleError = false

      return true
    },
  },
}
</script>
<template>
  <div class="">
    <el-date-picker
      v-model="dateValue"
      type="monthrange"
      :range-separator="rangeSeparator"
      :start-placeholder="startPlaceholder"
      :end-placeholder="endPlaceholder"
      :picker-options="pickerOptions"
      value-format="yyyy-MM"
      min-width="auto"
      style="width: 100%"
      :clearable="false"
    >
    </el-date-picker>
  </div>
</template>
<style scoped lang="scss"></style>
