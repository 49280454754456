<script>
import { getExportTableColumns } from '@/auth/utils'
import { tableColumns6 } from '@/views/statementManagement/fbStatement/tabCols'
import { tableColumns5 } from '@/views/statementManagement/ytStatement/tabCols'
import mapEnum from '@/enum/mapEnum'
import RadioGroup from '@/components/common/radio-group/RadioGroup.vue'
import { computed, reactive, ref } from '@vue/composition-api'
import Dialog from '@/components/Dialog/index.vue'
import { downloadFile } from '@/@core/utils/form'
import { useMessage } from '@/hooks/useMessage'
import { exportSettlement } from '../service'

export default {
  name: 'ExportDialog',
  components: { Dialog, RadioGroup },
  props: {
    formSearch: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      required: true,
    },
    cpType: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    const show = ref(true)
    const { message, loading } = useMessage()

    const headers = computed(() => ({
      ytHeaders: getExportTableColumns(tableColumns5, 'ytStatement-list'),
      fbHeaders: getExportTableColumns(tableColumns6, 'fbStatement-list'),
    }))

    const formData = reactive({
      exportOption: 0,
    })

    const close = () => {
      show.value = false
      setTimeout(() => {
        emit('close')
      }, 300)
    }

    const submit = () => {
      // 使用 some 方法检查是否有有效字段
      const hasValidField = Object.keys(props.formSearch).some(key => props.formSearch[key] !== undefined && props.formSearch[key] !== null)
      if (formData.exportOption === 2 && !hasValidField) {
        return message.warning('导出全部，检索项中至少需要填写一项，请检查后重试')
      }
      const loadingRef = loading()
      const { label } = mapEnum.exportType[props.cpType].find(item => item.value === formData.exportOption)
      exportSettlement({ ...formData, cpType: props.cpType, ...headers.value, ...props.formSearch }).then(data => {
        const fileNameDownload = `在 ${new Date().toLocaleString().replaceAll('/', '-')}导出的${label}报表`
        downloadFile(data.data, fileNameDownload)
        message.success('导出成功')
        close()
      }).catch(error => error)
        .finally(() => loadingRef.close())
    }

    return {
      show,
      close,
      submit,
      mapEnum,
      formData,
    }
  },
}
</script>
<template>
  <Dialog
    v-model="show"
    :title="title"
    width="520px"
    show-footer
    @cancel="close"
    @confirm="submit"
  >
    <div class="body-title">
      选择您需要导出的数据：
    </div>
    <RadioGroup v-model="formData.exportOption" :radio-list="mapEnum.exportType[cpType]" mandatory>
      <template #radio="{ row }">
        <span class="body-label">{{ row.label }}</span>
        <span v-if="row.subLabel" class="body-sub-label">({{ row.subLabel }})</span>
      </template>
    </RadioGroup>
  </Dialog>
</template>
<style scoped lang='scss'>
::v-deep .v-label{
  font-size: 15px !important;
}
.body-title{
  color: rgba(0,0,0,0.85);
  font-size: 15px;
}
.body-label{
  color: rgba(0,0,0,0.85);
}
.body-sub-label{
  color: rgba(0,0,0,0.4);
  font-size: 13px;
  margin-left: 8px;
}
</style>
