<script>
import { isFunction } from '@/@core/utils/is'
import TooltipBase from '@/components/common/tooltip-base/TooltipBase.vue'
import { processNumbers } from '@/@core/utils'
import { computed } from '@vue/composition-api'
import store from '@/store'

export default {
  name: 'XwTotalAmount',
  components: { TooltipBase },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const { buttonAuth } = store.getters
    // 过滤出有权限的按钮
    const newList = computed(() => props.list.filter(item => (isFunction(item.hidden) ? item.hidden(props.item) : !item.hidden)).filter(item => {
      if (!item.auth) return true
      if (Array.isArray(item.auth) && item.auth.length) {
        return item.auth.some(val => buttonAuth.includes(val))
      }

      return buttonAuth.includes(item.auth)
    }))

    return { processNumbers, newList }
  },
}
</script>
<template>
  <div class="total">
    <span v-for="(item,index) in newList" :key="index" class="items">
      <TooltipBase v-if="item.tooltip" :title="item.tooltip">
        <i class="iconfont icon-a-shuoming2x1 fs14"></i>
      </TooltipBase>
      {{ item.label }}:
      <span class="amount">
        {{ processNumbers(item.amount, item.unit) }}
      </span>
    </span>
  </div>
</template>
<style scoped lang='scss'>

.total{
  display: flex;
  flex-wrap: wrap;
  gap: 0 14px;
  color: rgba($color: #000000, $alpha:0.8);
  padding: 0 20px;
  font-size: 14px;
  width: max-content;

  .items{
    white-space: nowrap;

    .amount{
      color: #FFA80F;
    }
  }

}
</style>
