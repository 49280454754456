import mapEnum from '@/enum/mapEnum'

// 待财务审核-待客服二审
export const tableColumns1 = [
  { text: '', value: 'checkbox', minWidth: 50, type: 'checkbox' },
  { text: '结算单编号', value: 'settlementNo', minWidth: 200 },
  { text: '到账日期', value: 'receiptedAt', minWidth: 120, apiValue: 'receiptedAtYt' },
  { text: 'CP名称', value: 'cpName', minWidth: 200, showOverflow: false },
  { text: 'Payout Period', value: 'payoutPeriod', minWidth: 120, apiText: 'Payout Period' },
  { text: '频道名称/ID', value: 'channelName', minWidth: 250, showOverflow: false, apiText: '频道名称,频道ID' },
  { text: '子集名称', value: 'subsetName', minWidth: 150, showOverflow: false },
  { text: '运营团队', value: 'operationDept', minWidth: 100 },
  { text: '状态', value: 'checkStatus', minWidth: 120 },
  { text: '频道收益 ($)', value: 'revenue', minWidth: 120, align: 'right' },
  { text: '美国收益 ($)', value: 'revenueUs', minWidth: 120, align: 'right' },
  { text: '联邦税 ($)', value: 'federalTaxAmount', minWidth: 120, align: 'right' },
  { text: '可分配收益 ($)', value: 'distributableIncome', minWidth: 120, align: 'right' },
  { text: 'CP分成比例', value: 'proportion', minWidth: 100, align: 'right' },
  { text: '应发 ($)', value: 'expectedIncome', minWidth: 120, align: 'right' },
  { text: '手续费率', value: 'serviceCharge', minWidth: 120, align: 'right' },
  { text: '手续费 ($)', value: 'serviceChargeDollar', minWidth: 120, align: 'right' },
  { text: '实发 ($)', value: 'actualIncomeDollar', minWidth: 120, align: 'right' },
  { text: '汇率', value: 'rate', minWidth: 100, align: 'right' },
  { text: '调整币种', value: 'adjustCurrency', minWidth: 100, align: 'right' },
  { text: '调整项金额', value: 'adjustAmount', minWidth: 120, align: 'right' },
  { text: '实发 (￥)', value: 'actualIncomeRmb', minWidth: 120, align: 'right' },
  { text: '结算币种', value: 'currencyType', width: 100 },
  { text: '类型', value: 'settlementType', minWidth: 150, enum: mapEnum.statementTypeOptions },
  { text: '收款人', value: 'bankAccountName', minWidth: 200, showOverflow: false },
  { text: '证件号码', value: 'idCard', minWidth: 200 },
  { text: '开户行', value: 'openingBank', minWidth: 150 },
  { text: '收款账号', value: 'bankAccount', minWidth: 200 },
  { text: '客服', value: 'customer', minWidth: 100 },
  { text: '操作', value: 'actions', minWidth: 200, fixed: 'right' },
]
// 待客服审核
export const tableColumns2 = [
  { text: '', value: 'checkbox', minWidth: 50, type: 'checkbox' },
  { text: '结算单编号', value: 'settlementNo', minWidth: 200 },
  { text: '到账日期', value: 'receiptedAt', minWidth: 120, apiValue: 'receiptedAtYt' },
  { text: 'CP名称', value: 'cpName', minWidth: 200, showOverflow: false },
  { text: 'Payout Period', value: 'payoutPeriod', minWidth: 120, apiText: 'Payout Period' },
  { text: '频道名称/ID', value: 'channelName', minWidth: 250, showOverflow: false, apiText: '频道名称,频道ID' },
  { text: '子集名称', value: 'subsetName', minWidth: 150, showOverflow: false },
  { text: '运营团队', value: 'operationDept', minWidth: 100 },
  { text: '状态', value: 'checkStatus', minWidth: 120 },
  { text: '频道收益 ($)', value: 'revenue', minWidth: 120, align: 'right' },
  { text: 'CP分成比例', value: 'proportion', minWidth: 100, align: 'right' },
  { text: '手续费率', value: 'serviceCharge', minWidth: 120, align: 'right' },
  { text: '实发 ($)', value: 'actualIncomeDollar', minWidth: 120, align: 'right' },
  { text: '结算币种', value: 'currencyType', width: 100 },
  { text: '调整币种', value: 'adjustCurrency', minWidth: 100, align: 'right' },
  { text: '调整项金额', value: 'adjustAmount', minWidth: 120, align: 'right' },
  { text: '类型', value: 'settlementType', minWidth: 150, enum: mapEnum.statementTypeOptions },
  { text: '收款人', value: 'bankAccountName', minWidth: 200, showOverflow: false },
  { text: '证件号码', value: 'idCard', minWidth: 200 },
  { text: '开户行', value: 'openingBank', minWidth: 150 },
  { text: '收款账号', value: 'bankAccount', minWidth: 200 },
  { text: '客服', value: 'customer', minWidth: 100 },
  { text: '操作', value: 'actions', minWidth: 160, fixed: 'right' },
]
// 已推送
export const tableColumns3 = [
  { text: '', value: 'checkbox', minWidth: 50, type: 'checkbox' },
  { text: '结算单编号', value: 'settlementNo', minWidth: 200 },
  { text: '到账日期', value: 'receiptedAt', minWidth: 120, apiValue: 'receiptedAtYt' },
  { text: 'CP名称', value: 'cpName', minWidth: 200, showOverflow: false },
  { text: 'Payout Period', value: 'payoutPeriod', minWidth: 120, apiText: 'Payout Period' },
  { text: '频道名称/ID', value: 'channelName', minWidth: 250, showOverflow: false, apiText: '频道名称,频道ID' },
  { text: '子集名称', value: 'subsetName', minWidth: 150, showOverflow: false },
  { text: '运营团队', value: 'operationDept', minWidth: 100 },
  { text: '状态', value: 'checkStatus', minWidth: 120 },
  { text: '发票状态', value: 'invoiceStatus', minWidth: 120 },
  { text: '汇款状态', value: 'paymentStatus', minWidth: 120, showOverflow: false },
  { text: '频道收益 ($)', value: 'revenue', minWidth: 120, align: 'right' },
  { text: '美国收益 ($)', value: 'revenueUs', minWidth: 120, align: 'right' },
  { text: '联邦税 ($)', value: 'federalTaxAmount', minWidth: 120, align: 'right' },
  { text: '可分配收益 ($)', value: 'distributableIncome', minWidth: 120, align: 'right' },
  { text: 'CP分成比例', value: 'proportion', minWidth: 100, align: 'right' },
  { text: '应发 ($)', value: 'expectedIncome', minWidth: 120, align: 'right' },
  { text: '手续费率', value: 'serviceCharge', minWidth: 120, align: 'right' },
  { text: '手续费 ($)', value: 'serviceChargeDollar', minWidth: 120, align: 'right' },
  { text: '实发 ($)', value: 'actualIncomeDollar', minWidth: 120, align: 'right' },
  { text: '汇率', value: 'rate', minWidth: 100, align: 'right' },
  { text: '调整币种', value: 'adjustCurrency', minWidth: 100, align: 'right' },
  { text: '调整项金额', value: 'adjustAmount', minWidth: 120, align: 'right' },
  { text: '实发 (￥)', value: 'actualIncomeRmb', minWidth: 120, align: 'right' },
  { text: '结算币种', value: 'currencyType', width: 100 },
  { text: '类型', value: 'settlementType', minWidth: 150, enum: mapEnum.statementTypeOptions },
  { text: '收款人', value: 'bankAccountName', minWidth: 200, showOverflow: false },
  { text: '证件号码', value: 'idCard', minWidth: 200 },
  { text: '开户行', value: 'openingBank', minWidth: 150 },
  { text: '收款账号', value: 'bankAccount', minWidth: 200 },
  { text: '客服', value: 'customer', minWidth: 100 },
]
// 暂不结算
export const tableColumns4 = [
  { text: '', value: 'checkbox', minWidth: 50, type: 'checkbox' },
  { text: '结算单编号', value: 'settlementNo', minWidth: 200 },
  { text: '到账日期', value: 'receiptedAt', minWidth: 120, apiValue: 'receiptedAtYt' },
  { text: '所属月份', value: 'month', minWidth: 120 },
  { text: 'CP名称', value: 'cpName', minWidth: 200, showOverflow: false },
  { text: 'Payout Period', value: 'payoutPeriod', minWidth: 120, apiText: 'Payout Period' },
  { text: '频道名称/ID', value: 'channelName', minWidth: 250, showOverflow: false, apiText: '频道名称,频道ID' },
  { text: '子集名称', value: 'subsetName', minWidth: 150, showOverflow: false },
  { text: '运营团队', value: 'operationDept', minWidth: 100 },
  { text: '原因', value: 'remark', minWidth: 180, showOverflow: false },
  { text: '频道收益 ($)', value: 'revenue', minWidth: 120, align: 'right' },
  { text: '美国收益 ($)', value: 'revenueUs', minWidth: 120, align: 'right' },
  { text: '联邦税 ($)', value: 'federalTaxAmount', minWidth: 120, align: 'right' },
  { text: '可分配收益 ($)', value: 'distributableIncome', minWidth: 120, align: 'right' },
  { text: 'CP分成比例', value: 'proportion', minWidth: 100, align: 'right' },
  { text: '应发 ($)', value: 'expectedIncome', minWidth: 120, align: 'right' },
  { text: '手续费率', value: 'serviceCharge', minWidth: 120, align: 'right' },
  { text: '手续费 ($)', value: 'serviceChargeDollar', minWidth: 120, align: 'right' },
  { text: '实发 ($)', value: 'actualIncomeDollar', minWidth: 120, align: 'right' },
  { text: '汇率', value: 'rate', minWidth: 100, align: 'right' },
  { text: '调整币种', value: 'adjustCurrency', minWidth: 100, align: 'right' },
  { text: '调整项金额', value: 'adjustAmount', minWidth: 120, align: 'right' },
  { text: '实发 (￥)', value: 'actualIncomeRmb', minWidth: 120, align: 'right' },
  { text: '结算币种', value: 'currencyType', width: 100 },
  { text: '类型', value: 'settlementType', minWidth: 150, enum: mapEnum.statementTypeOptions },
  { text: '收款人', value: 'bankAccountName', minWidth: 200, showOverflow: false },
  { text: '证件号码', value: 'idCard', minWidth: 200 },
  { text: '开户行', value: 'openingBank', minWidth: 150 },
  { text: '收款账号', value: 'bankAccount', minWidth: 200 },
  { text: '客服', value: 'customer', minWidth: 100 },
  { text: '操作', value: 'actions', minWidth: 160, fixed: 'right' },
]
// 全部
export const tableColumns5 = [
  { text: '结算单编号', value: 'settlementNo', minWidth: 200 },
  { text: '到账日期', value: 'receiptedAt', minWidth: 120, apiValue: 'receiptedAtYt' },
  { text: 'CP名称', value: 'cpName', minWidth: 200, showOverflow: false },
  { text: 'Payout Period', value: 'payoutPeriod', minWidth: 120, apiText: 'Payout Period' },
  { text: '频道名称/ID', value: 'channelName', minWidth: 250, showOverflow: false, apiText: '频道名称,频道ID' },
  { text: '子集名称', value: 'subsetName', minWidth: 150, showOverflow: false },
  { text: '运营团队', value: 'operationDept', minWidth: 100 },
  { text: '状态', value: 'checkStatus', minWidth: 120 },
  { text: '发票状态', value: 'invoiceStatus', minWidth: 120 },
  { text: '汇款状态', value: 'paymentStatus', minWidth: 120, showOverflow: false },
  { text: '频道收益 ($)', value: 'revenue', minWidth: 120, align: 'right' },
  { text: '美国收益 ($)', value: 'revenueUs', minWidth: 120, align: 'right' },
  { text: '联邦税 ($)', value: 'federalTaxAmount', minWidth: 120, align: 'right' },
  { text: '可分配收益 ($)', value: 'distributableIncome', minWidth: 120, align: 'right' },
  { text: 'CP分成比例', value: 'proportion', minWidth: 100, align: 'right' },
  { text: '应发 ($)', value: 'expectedIncome', minWidth: 120, align: 'right' },
  { text: '手续费率', value: 'serviceCharge', minWidth: 120, align: 'right' },
  { text: '手续费 ($)', value: 'serviceChargeDollar', minWidth: 120, align: 'right' },
  { text: '实发 ($)', value: 'actualIncomeDollar', minWidth: 120, align: 'right' },
  { text: '汇率', value: 'rate', minWidth: 100, align: 'right' },
  { text: '调整币种', value: 'adjustCurrency', minWidth: 100, align: 'right' },
  { text: '调整项金额', value: 'adjustAmount', minWidth: 120, align: 'right' },
  { text: '实发 (￥)', value: 'actualIncomeRmb', minWidth: 120, align: 'right' },
  { text: '结算币种', value: 'currencyType', width: 100 },
  { text: '类型', value: 'settlementType', minWidth: 150, enum: mapEnum.statementTypeOptions },
  { text: '收款人', value: 'bankAccountName', minWidth: 200, showOverflow: false },
  { text: '证件号码', value: 'idCard', minWidth: 200 },
  { text: '开户行', value: 'openingBank', minWidth: 150 },
  { text: '收款账号', value: 'bankAccount', minWidth: 200 },
  { text: '客服', value: 'customer', minWidth: 100 },
]
