import http from '@http'

/**
 * CP数据统计分页列表
 * @param {*} params
 * @returns
 */
export function getPage(params) {
  return http.post('/cpDataStatistics/page', params)
}

/**
 * CP数据统计金额汇总
 * @param {*} params
 * @returns
 */
export function pageStatistics(params) {
  return http.post('/cpDataStatistics/sum', params)
}

/**
 * CP结算单数据统计导出
 * @param {*} params
 * @returns
 */
export function exportSettlement(params) {
  return http.downloadGet('/cpDataStatistics/exportSettlement', params)
}

/**
 * CP数据统计更新
 * @param {*} params
 * @returns
 */
export function updateInit(params) {
  return http.post('/cpDataStatistics/reStatistic', params)
}
