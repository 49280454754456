<script>
import { addSpace } from '@/@core/utils/index'

export default {
  name: 'VlDialog',
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: Boolean,
    title: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '500px',
    },
    showClose: {
      type: Boolean,
      default: true,
    },
    showFooter: Boolean,
    confirmBtnText: {
      type: String,
      default: '确认',
    },
    cancelBtnText: {
      type: String,
      default: '取消',
    },
    showCancelBtnText: {
      type: Boolean,
      default: true,
    },
    rejectBtnText: {
      type: String,
      default: '驳回',
    },
    showRejectBtnText: {
      type: Boolean,
      default: false,
    },
    subtitleText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      addSpace,
    }
  },
}
</script>

<template>
  <v-dialog :value="value" :persistent="value" :width="width">
    <div class="dialog">
      <div class="dialog-header">
        <div class="dialog-header-title">
          {{ title }} <span v-if="subtitleText" class="dialog-header-subtitle">{{ subtitleText }}</span>
        </div>
        <i v-if="showClose" class="iconfont icon-a-guanbi2x fs18" @click="$emit('cancel')" />
      </div>
      <hr class="message_hr" />
      <div class="dialog-body">
        <slot />
      </div>
      <div v-if="showFooter" class="dialog-footer">
        <v-btn v-if="showCancelBtnText" outlined @click="$emit('cancel')">
          {{ addSpace(cancelBtnText) }}
        </v-btn>
        <v-btn v-if="showRejectBtnText" outlined @click="$emit('reject')">
          {{ addSpace(rejectBtnText) }}
        </v-btn>
        <v-btn color="primary" @click="$emit('confirm')">
          {{ addSpace(confirmBtnText) }}
        </v-btn>
      </div>
    </div>
  </v-dialog>
</template>

<style scoped lang="scss">
.dialog {
  background: #ffffff;
  border-right: 4px;
  padding: 0 24px;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;

    &-title {
      font-weight: 600;
      font-size: 18px;
      color: #000000;
    }
    &-subtitle{
      font-weight: normal;
      font-size: 16px;
      color: rgba($color: #000000, $alpha: 0.8);
    }

    .iconfont {
      font-size: 18px !important;
      cursor: pointer;
    }
  }

  &-body {
    padding: 20px 0;
  }

  &-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    padding-bottom: 20px;
  }

  .message_hr {
    background-color: #f2f3f5;
    height: 1px;
    border: none;
  }
}
</style>
